import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Header from "./pages/Header"; // Import the Header component
import About from "./pages/About";
import Auth from "./pages/OAuth";
import UserInput from "./pages/UserInput";
import { useSelector } from "react-redux";

function App() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return (
    <BrowserRouter>
      <Header />
      <main>
        <Routes>
          {/* Redirect to /login if not authenticated */}
          <Route
            path="/"
            element={
              isAuthenticated ? (
                <Navigate to="/userInput" />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route path="/about" element={<About />} />
          <Route path="/login" element={<Auth />} />
          <Route
            path="/userInput"
            element={isAuthenticated ? <UserInput /> : <Navigate to="/login" />}
          />
        </Routes>
      </main>
    </BrowserRouter>
  );
}

export default App;
