import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { login, logout } from "../store/infoSlice"; // Redux actions
import Login from "./login/Login";

function Auth() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); // Access auth state from Redux
  const dispatch = useDispatch(); // Dispatch Redux actions
  const navigate = useNavigate();

  const handleLogin = () => {
    window.location.href = `${process.env.REACT_APP_BACKEND}/auth/login`;
  };

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/auth/isAuthenticated`,
          {
            credentials: "include",
          }
        );
        const data = await response.json();
        if (data.authenticated) {
          dispatch(login()); // Update Redux store
          navigate("/userInput"); // Redirect to userInput after login
        } else {
          dispatch(logout()); // Update Redux store
        }
      } catch (error) {
        console.error("Error checking authentication status:", error);
        dispatch(logout()); // Update Redux store
      }
    };

    checkAuthStatus();
  }, [dispatch, navigate]);

  return (
    <div>
      {isAuthenticated ? (
        <div>Loading...</div>
      ) : (
        <Login handleLogin={handleLogin} />
      )}
    </div>
  );
}

export default Auth;
