import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFinalArray } from "../store/userSlice"; // Import the Redux action
import Calendar from "./Calendar";
import Loading from "./Loading";

function LLMText() {
  const dispatch = useDispatch(); // Initialize dispatch for Redux

  // Access places and date from Redux
  const places = useSelector((state) => state.user.finalArray);
  const [loadedPlaces, setLoadedPlaces] = useState([]);
  const [selectedPlaces, setSelectedPlaces] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);

  useEffect(() => {
    const fetchAIPlaces = async () => {
      setError(null);
      setLoading(true);

      try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/backend`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            places: places,
          }),
        });

        if (!res.ok) {
          throw new Error("Unable to fetch response from the API");
        }

        const data = await res.json();
        setLoadedPlaces(data.topPlaces); // Populate loaded places
      } catch (error) {
        console.error(error.message);
        setError("Failed to fetch response from the AI API");
      } finally {
        setLoading(false);
      }
    };

    if (places && places.length > 0) {
      fetchAIPlaces();
    }
  }, [places]);

  // Toggle selection of a place
  const togglePlaceSelection = (place) => {
    setSelectedPlaces((prevSelected) => {
      const isSelected = prevSelected.find(
        (selectedPlace) => selectedPlace.name === place.name
      );
      if (isSelected) {
        return prevSelected.filter(
          (selectedPlace) => selectedPlace.name !== place.name
        );
      } else {
        return [...prevSelected, place];
      }
    });
  };

  // Handle navigation to the calendar page
  const handleShowCalendar = () => {
    // Store selected places in Redux finalArray
    dispatch(setFinalArray(selectedPlaces));
    setShowCalendar(true); // Switch to the calendar view
  };

  // Display the loaded places and allow user interaction
  return (
    <>
      {loading ? (
        <Loading />
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : showCalendar ? (
        <Calendar />
      ) : (
        <div className="flex flex-col items-center min-h-screen bg-gradient-to-b from-[#0f3443] to-[#34e89e] py-8 px-4">
          <h2 className="text-3xl font-bold text-white mb-4">
            AI-Suggested Places for You
          </h2>
          <h3 className="text-xl text-gray-100 mb-6">
            Select the ones that interest you
          </h3>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-4xl">
            {loadedPlaces.map((place, index) => (
              <div
                key={index}
                onClick={() => togglePlaceSelection(place)}
                className={`p-4 border rounded-lg shadow-md cursor-pointer hover:shadow-lg transition-transform transform hover:scale-105 ${
                  selectedPlaces.some(
                    (selected) => selected.name === place.name
                  )
                    ? "bg-[#34e89e] border-[#0f3443] text-white"
                    : "bg-white text-gray-700"
                }`}
              >
                <strong className="text-lg block mb-2">{place.name}</strong>
                <p className="text-md block">{place.category}</p>
                {/* Render the website link if available */}
                {place.website && place.website !== "N/A" && (
                  <a
                    href={place.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm text-[#0f3443] underline hover:text-[#34e89e] block mb-2 truncate"
                  >
                    Visit Website
                  </a>
                )}
                <p className="text-sm block">{place.address}</p>
              </div>
            ))}
          </div>

          <button
            onClick={handleShowCalendar}
            disabled={selectedPlaces.length === 0} // Disable if no places are selected
            className={`mt-6 py-2 px-6 rounded-lg font-semibold shadow-md transition-colors ${
              selectedPlaces.length > 0
                ? "bg-[#34e89e] text-[#0f3443] hover:bg-[#0f3443] hover:text-[#34e89e] cursor-pointer"
                : "bg-gray-400 text-gray-700 cursor-not-allowed"
            }`}
          >
            Go to Calendar
          </button>
        </div>
      )}
    </>
  );
}

export default LLMText;
