import { useJsApiLoader } from "@react-google-maps/api";
import { useState, useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { setFinalArray } from "../store/userSlice"; // Redux action
import LLMText from "./LLMText";
import Loading from "./Loading";

const libraries = ["places"];

function TextAPISearch({ city }) {
  const [restaurants, setRestaurants] = useState([]);
  const [landMarks, setLandMarks] = useState([]);
  const [loading, setLoading] = useState(true); // Track loading state
  const serviceRef = useRef(null);
  const dispatch = useDispatch(); // To dispatch actions

  // Load API
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: libraries,
  });

  // Initialize PlacesService
  useEffect(() => {
    if (isLoaded && window.google && !serviceRef.current) {
      serviceRef.current = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
    }
  }, [isLoaded]);

  // Fetch Place Details
  const fetchPlaceDetails = useCallback(
    (placeId) =>
      new Promise((resolve) => {
        if (serviceRef.current) {
          const request = {
            placeId,
            fields: [
              "name",
              "formatted_address",
              "opening_hours",
              "rating",
              "website",
            ],
          };

          serviceRef.current.getDetails(request, (details, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              resolve(details);
            } else {
              console.error(`Failed to fetch details for placeId ${placeId}`);
              resolve(null); // Handle failure gracefully
            }
          });
        }
      }),
    []
  );

  // Helper function to format the data
  const formatArrayWithDetails = useCallback(
    async (array) => {
      const detailedPlaces = await Promise.all(
        array.map(async (place) => {
          const details = await fetchPlaceDetails(place.place_id);
          return {
            place_id: place.place_id,
            name: details?.name || place.name,
            address: details?.formatted_address || place.formatted_address,
            opening_hours: details?.opening_hours?.weekday_text || [],
            rating: details?.rating || place.rating,
            website: details?.website || "",
          };
        })
      );
      return detailedPlaces;
    },
    [fetchPlaceDetails]
  );

  // Fetch restaurants
  const fetchRestaurants = useCallback(() => {
    if (serviceRef.current) {
      const request =
        city.latitude && city.longitude
          ? {
              location: new window.google.maps.LatLng(
                city.latitude,
                city.longitude
              ),
              radius: 1000, // Search within 5km radius
              type: "restaurant",
            }
          : {
              query: `Restaurants in ${city}`,
              fields: ["place_id", "name", "formatted_address"],
            };

      serviceRef.current.textSearch(request, async (results, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          results
        ) {
          const detailedRestaurants = await formatArrayWithDetails(
            results.slice(0, 15)
          );
          setRestaurants(detailedRestaurants);
        } else {
          setRestaurants([]);
          console.error("No restaurants found or error occurred:", status);
        }
      });
    }
  }, [city, formatArrayWithDetails]);

  // Fetch landmarks
  const fetchLandMarks = useCallback(() => {
    if (serviceRef.current) {
      const request =
        city.latitude && city.longitude
          ? {
              location: new window.google.maps.LatLng(
                city.latitude,
                city.longitude
              ),
              radius: 1000, // Search within 5km radius
              type: "tourist_attraction",
            }
          : {
              query: `Landmarks in ${city}`,
              fields: ["place_id", "name", "formatted_address"],
            };

      serviceRef.current.textSearch(request, async (results, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          results
        ) {
          const detailedLandmarks = await formatArrayWithDetails(
            results.slice(0, 15)
          );
          setLandMarks(detailedLandmarks);
        } else {
          setLandMarks([]);
          console.error("No landmarks found or error occurred:", status);
        }
      });
    }
  }, [city, formatArrayWithDetails]);

  // Fetch data when `isLoaded` changes or `city` changes
  useEffect(() => {
    if (isLoaded) {
      fetchRestaurants();
      fetchLandMarks();
    }
  }, [isLoaded, fetchRestaurants, fetchLandMarks]);

  // Update `finalArray` in Redux and manage loading state
  useEffect(() => {
    if (restaurants.length || landMarks.length) {
      const finalArray = [...restaurants, ...landMarks];
      dispatch(setFinalArray(finalArray)); // Save to Redux
    }

    // Stop loading when both data sources are processed
    if (restaurants.length > 0 && landMarks.length > 0) {
      setLoading(false);
    }
  }, [restaurants, landMarks, dispatch]);

  return <div>{loading ? <Loading /> : <LLMText />}</div>;
}

export default TextAPISearch;
