import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    city: {},
    selectedDate: "",
    showCalendar: false,
    restaurants: [],
    landMarks: [],
    finalArray: [],
  },
  reducers: {
    setCity: (state, action) => {
      state.city = action.payload;
    },
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload;
    },
    toggleCalendar: (state, action) => {
      state.showCalendar = action.payload; // true or false
    },
    setRestaurants: (state, action) => {
      state.restaurants = action.payload;
    },
    setLandmarks: (state, action) => {
      state.landMarks = action.payload;
    },
    setFinalArray: (state, action) => {
      state.finalArray = action.payload;
    },
    resetUserState: (state) => {
      // Reset all user-related fields to their initial state
      state.city = {};
      state.selectedDate = "";
      state.showCalendar = false;
      state.restaurants = [];
      state.landMarks = [];
      state.finalArray = [];
    },
  },
});

export const {
  setCity,
  setSelectedDate,
  toggleCalendar,
  setRestaurants,
  setLandmarks,
  setFinalArray,
  resetUserState,
} = userSlice.actions;

export default userSlice.reducer;
