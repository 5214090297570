import React from "react";

function Loading() {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="flex flex-col items-center">
        {/* Spinner */}
        <div className="loader ease-linear rounded-full border-4 border-t-4 border-[#34e89e] h-12 w-12 mb-4"></div>
        <p className="text-black font-semibold">
          Loading resources, please wait...
        </p>
      </div>

      {/* Spinner Styles */}
      <style jsx>{`
        .loader {
          border-top-color: transparent;
          animation: spin 1s linear infinite;
        }
        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </div>
  );
}

export default Loading;
