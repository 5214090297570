import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    selectedDate: "",
    places: [],
  },
  reducers: {
    login: (state) => {
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.selectedDate = "";
      state.places = [];
    },
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload;
    },
    setPlaces: (state, action) => {
      state.places = action.payload;
    },
  },
});

export const { login, logout, setSelectedDate, setPlaces } = authSlice.actions;
export default authSlice.reducer;
