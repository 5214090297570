import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../store/infoSlice";
import { useNavigate } from "react-router-dom";

function Header() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); // Get auth state from Redux
  const dispatch = useDispatch(); // Dispatch Redux actions
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/auth/logout`,
        {
          method: "POST",
          credentials: "include",
        }
      );
      if (response.ok) {
        dispatch(logout()); // Update Redux state
        navigate("/");
      } else {
        console.error("Failed to log out.");
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <header className="bg-gradient-to-r from-[#0f3443] to-[#34e89e] p-4 shadow-lg">
      <nav className="flex items-center justify-center max-w-6xl mx-auto">
        <NavLink to="/" className="flex-grow">
          <h1 className="text-5xl font-bold text-white text-center">DaySync</h1>
        </NavLink>
        <div className="flex gap-6 items-center absolute right-8">
          <NavLink
            to="/about"
            className={({ isActive }) =>
              isActive
                ? "text-white bg-[#34e89e] px-4 py-2 rounded-lg"
                : "text-white hover:bg-[#0f3443] hover:text-white px-4 py-2 rounded-lg transition-all duration-300"
            }
          >
            About Us
          </NavLink>
          {isAuthenticated && (
            <button
              onClick={handleLogout}
              className="text-white hover:bg-[#0f3443] hover:text-white px-4 py-2 rounded-lg transition-all duration-300"
            >
              Logout
            </button>
          )}
        </div>
      </nav>
    </header>
  );
}

export default Header;
