import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./infoSlice";
import userReducer from "./userSlice"; // Import userSlice

const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer, // Add user slice
  },
});

export default store;
