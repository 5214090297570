import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCity, setSelectedDate, toggleCalendar } from "../store/userSlice";
import TextAPISearch from "../components/TextAPISearch";

function UserInput() {
  const dispatch = useDispatch();

  const city = useSelector((state) => state.user.city);
  const date = useSelector((state) => state.user.selectedDate);
  const showCalendar = useSelector((state) => state.user.showCalendar);

  const [useCurrentLocation, setUseCurrentLocation] = useState(false);
  const [loadingLocation, setLoadingLocation] = useState(false);

  const handleCityChange = (event) => {
    dispatch(setCity({ city: event.target.value }));
  };

  const handleDateChange = (event) => {
    dispatch(setSelectedDate(event.target.value));
  };

  const handleLocationOptionChange = (event) => {
    const selectedOption = event.target.value === "current";
    setUseCurrentLocation(selectedOption);
    if (selectedOption) {
      dispatch(setCity({ city: "", latitude: null, longitude: null }));
    } else {
      dispatch(setCity({ city: "" }));
    }
  };

  const handleStartPlanning = () => {
    if (useCurrentLocation) {
      setLoadingLocation(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          dispatch(setCity({ latitude, longitude, city: "" }));
          setLoadingLocation(false);
          dispatch(toggleCalendar(true));
        },
        (error) => {
          console.error("Error fetching location:", error);
          setLoadingLocation(false);
          alert("Unable to fetch current location. Please try again.");
        }
      );
    } else {
      dispatch(toggleCalendar(true));
    }
  };

  const isButtonDisabled = () => {
    return (
      !date || (!useCurrentLocation && (!city || !city.city)) || loadingLocation
    );
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      {!showCalendar ? (
        <div className="bg-white p-8 rounded-lg shadow-lg w-80 text-center">
          <h1 className="text-2xl font-semibold mb-6 text-gray-800">
            Plan Your Visit
          </h1>
          <div className="mb-4">
            <label className="block text-gray-600 mb-2">Location</label>
            <div className="flex items-center mb-2">
              <input
                type="radio"
                id="cityOption"
                value="city"
                checked={!useCurrentLocation}
                onChange={handleLocationOptionChange}
                className="mr-2"
              />
              <label htmlFor="cityOption" className="text-gray-600">
                Type Location
              </label>
            </div>
            <div className="flex items-center mb-2">
              <input
                type="radio"
                id="currentLocationOption"
                value="current"
                checked={useCurrentLocation}
                onChange={handleLocationOptionChange}
                className="mr-2"
              />
              <label htmlFor="currentLocationOption" className="text-gray-600">
                Use Current Location
              </label>
            </div>
            <input
              type="text"
              id="city"
              value={city.city || ""}
              onChange={handleCityChange}
              placeholder="Enter city name"
              disabled={useCurrentLocation}
              className={`w-full px-4 py-2 border rounded-md focus:outline-none ${
                useCurrentLocation
                  ? "border-gray-200 bg-gray-200 text-gray-500 cursor-not-allowed"
                  : "border-gray-300 bg-white text-black"
              }`}
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-600 mb-1" htmlFor="date">
              Date
            </label>
            <input
              type="date"
              id="date"
              value={date}
              onChange={handleDateChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 bg-white"
            />
          </div>
          <button
            onClick={handleStartPlanning}
            disabled={isButtonDisabled()}
            className={`w-full py-2 rounded-md font-semibold transition-colors ${
              isButtonDisabled()
                ? "bg-gray-400 text-gray-200 cursor-not-allowed"
                : "bg-blue-600 text-white hover:bg-blue-700"
            }`}
          >
            {loadingLocation ? "Fetching Location..." : "Start Planning"}
          </button>
        </div>
      ) : (
        <TextAPISearch city={useCurrentLocation ? city : city.city} />
      )}
    </div>
  );
}

export default UserInput;
